// import logo from './logo.svg';

import './App.css';
import React from 'react';
import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, NavLink} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AuthProvider } from 'react-oidc-context';

import MainLayout from './components/MainLayout';
import UserAuth from './components/UserAuth';
import Wizard1 from './components/Wizard1';
import Wizard2 from './components/Wizard2'; 
// import Wizard3 from './components/Wizard3';
import TasksPane from './components/TasksPane';
// import oidcConfig from './oidcConfig';
import ByeByePage from './components/ByeByePage';
import ProtectedRoute from './components/ProtectedRoute';
import SlIcon from '@shoelace-style/shoelace/dist/react/icon';

import Hi from './components/Hi';
const App = () => {
  const [oidcConfig, setOidcConfig] = useState(null);
  const { t } = useTranslation(); 
  useEffect(() => {
    fetch('/config/oidcConfig.js')
      .then((response) => response.json())
      .then((config) => setOidcConfig(config));
  }, []);
  // const location = useLocation();
  document.body.classList.add('sl-theme-dark');
  if (!oidcConfig) {
    // Render loading state, or handle as needed
    return <div>Loading...</div>;
  }
  return (
    <AuthProvider {...oidcConfig}>
              <Router>

    <UserAuth render={({ handleLogout, user, scopes }) => (
      
          <MainLayout handleLogout={handleLogout} user={user}>
            <aside id="routeMenu">
            <nav className={!user ? 'hidden' : ''}>
              <ul className="nav-menu" >
                <li>
                  <ul className="nav-submenu">
                    { scopes.access_tracks && <li><NavLink to="/wizard1"><SlIcon src="/gpx-icon.svg" />{t('GPX Device Data Importer')}</NavLink></li> }
                    { scopes.access_planning && <li><NavLink to="/wizard2"><SlIcon name="map" />{t('Planning Geo Data Importer')}</NavLink></li> }
                  
                    {/* <li><NavLink to="/wizard3">{t('Cumulo Geo Data Importer')}</NavLink></li> */}
                  </ul>
                </li>
                <li><NavLink to="/tasks"><SlIcon name="list-task" />Tasks</NavLink></li>
                
              </ul>
            </nav>
            </aside>
            <div id="routeContent">
            
            <Routes>
              <Route path="/" element={<Hi></Hi>}></Route>
              <Route path="/wizard1" element={<ProtectedRoute scope="access_tracks" user={user} scopes={scopes}><Wizard1 /></ProtectedRoute>} />
              <Route path="/wizard2" element={<ProtectedRoute scope="access_planning" user={user} scopes={scopes}><Wizard2 /></ProtectedRoute>} /> 
              {/* <Route path="/wizard3" element={<Wizard3 />} />  */}
              <Route path="/tasks" element={<ProtectedRoute user={user} scopes={scopes}><TasksPane /></ProtectedRoute>} />
              <Route path="/byebye" element={<ByeByePage />} />
            </Routes>

            </div>
          </MainLayout>
      )}
    />
            </Router>

    </AuthProvider>

  );
};

export default App;
