import React from 'react';
const ByeByePage = () => {

    return (
      <div>
        <h1>You have been logged out</h1>
        <button onClick={() => {
            window.location.href = '/';
        }}>Go to Home</button>
      </div>
    );
  };

  export default ByeByePage;