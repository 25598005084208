import { useState, useEffect } from 'react';

const useStepValidation = (currentStep, stepIsValid) => {
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    setIsValid(stepIsValid(currentStep));
  }, [currentStep, stepIsValid]);

  return isValid;
};

export default useStepValidation;