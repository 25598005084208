import React, {useRef, useState} from 'react';
import SlAvatar from '@shoelace-style/shoelace/dist/react/avatar';
import SlIconButton from '@shoelace-style/shoelace/dist/react/icon-button';
import CirrusLogo from './CirrusLogo';
const MainLayout = ({ handleLogout, user, children }) => {
    const [isLoading, setIsLoading] = useState(true);
    const nodeRef = useRef(null);
    // console.log('MainLayout', handleLogout, user, children);
    let initials= user?.profile?.name?.split(' ').map((n)=>n[0]).join('');
    // useEffect(() => {
    //     setIsLoading(true);
    
         
    //  }, [location]);
    setTimeout( ()=>{
        setIsLoading(false);
   },1100)
   
  return (
    <div id='app'>
      <header id="appHeader" className={isLoading ? 'loading' : '' }>
        <CirrusLogo />
        <h1>Geo Importer</h1> 
        
        <span className="flex-spacer"></span>
        <div id="toolbar" className={ user ? '' : 'hidden'}>
         <div id="user" className={ user ? '' : 'hidden'}>
            <SlAvatar size="small" image={user?.profile?.picture} initials={initials} shape="circle"></SlAvatar>
            <span>{user?.profile?.name}</span>
        </div>
        { handleLogout && <SlIconButton style={{ fontSize: '2rem'}} name="box-arrow-right" label="Settings" onclick={handleLogout}/> }
        </div>
       
      </header>
      {/* <TransitionGroup>
      <CSSTransition key={location.key} classNames="fade" timeout={300} nodeRef={nodeRef}> */}
      <main id="appBody" ref={nodeRef}>
      
        {children}
        
      </main>
      {/* </CSSTransition>
        </TransitionGroup> */}
      <footer id="appFooter">
        <p>&copy; 2024 Gestion Cirrus Inc.</p>
      </footer>
    </div>
  );
};

export default MainLayout;