import React from 'react';
import SlIcon from '@shoelace-style/shoelace/dist/react/icon';
import { useAuth } from 'react-oidc-context';

const Hi = () => {
        const auth = useAuth();
        const isAuth = auth.isAuthenticated;
        return (
            isAuth ? <div className="welcome-arrow"><SlIcon name="arrow-return-left" /></div> : <div></div>
        );
    };

  export default Hi;