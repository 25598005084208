// fetchWithAuth.js

const useFetchWithAuth = (auth)=>{
    return async (url, options = {}) => {
  

        // Ensure the user is authenticated and the token is available
        if (!auth.isAuthenticated || !auth.user || !auth.user.access_token) {
          // Redirect to login or handle according to your app's flow
          window.location.href = '/byebye';
          return;
        }
      
        // Add Authorization header to the request
        const headers = {
          ...options.headers,
          Authorization: `Bearer ${auth.user.access_token}`,
        };
      
        try {
          const response = await fetch(url, { ...options, headers });
          if (response.status === 401) {
            // Handle unauthorized access
            auth.signinRedirect();
            return;
          }
          return response;
        } catch (error) {
          console.error('Fetch error:', error);
        }
      };
}

export default useFetchWithAuth;
