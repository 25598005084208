import { useNavigate } from 'react-router-dom';

const ProtectedRoute = ({ children, user, scope, scopes }) => {
  const navigate = useNavigate();
  
  if (!user) {
    // navigate('/byebye');
    return null;
  }
  
  let userHasScope = scope ? scopes[scope] : true;
  if(!userHasScope){
    setTimeout( ()=>{
        navigate('/')
    },1000)
    
    return null;
  }
  return children;
};

export default ProtectedRoute;