import React, { useEffect, useState } from 'react';
import { useAuth, hasAuthParams } from 'react-oidc-context';
import { useNavigate, useLocation } from 'react-router-dom';

const UserAuth = ({ render }) => {
  const auth = useAuth();
  const [hasTriedSignin, setHasTriedSignin] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (!hasAuthParams() &&
      !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading &&
      !hasTriedSignin && location.pathname !== '/byebye'
    ) {
      auth.signinRedirect();
      setHasTriedSignin(true);
    }

    if(auth.isAuthenticated && (location.pathname === '/byebye' || location.pathname === '/authentication/callback' )) {
      navigate('/');
    }
    // we lock certain routes based on scopes and redirect to /byebye if the user doesn't have the right scopes
    if (auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading && !auth.user?.scope?.includes('web_importer')) {
      navigate('/byebye');
    }
    

  }, [auth, hasTriedSignin]);

  const handleLogout = () => {
    auth.removeUser();
    navigate('/byebye');
  };
  console.log('auth', auth?.user)
  return render ? render({ handleLogout, user: auth.user, scopes: auth?.user?.profile?.web_importer||{} }) : (<p>bye</p>);
};

export default UserAuth;
