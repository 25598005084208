import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      "GPX_IMPORTER_WIZARD_TITLE": "GPX Importer",
      "GPX_IMPORTER_WIZARD_DESCRIPTION": "Use this tool for importing raw GPX files with GPS tracks related to Harvester, Grapple, Delimber, or Human (flagging) only.",
      "PLAN_IMPORTER_WIZARD_TITLE": "Planification Importer",
      "PLAN_IMPORTER_WIZARD_DESCRIPTION": "Upload contours, exclusions, or road planification shapes as separate zip files. Each shape type (contour, exclusion, road) must be in its individual zip file, named accordingly (e.g., \"contour_1.zip\" for a contour). Ensure each zip file contains all necessary shapefile components (.shp, .shx, .rpj, etc.). You can upload multiple zip files simultaneously, but they should not be combined into one zip file.",
      "BUTTON_UPLOAD": "Upload",
      "BUTTON_IMPORT": "Import",
      "BUTTON_NEXT": "Next",
      "W1_STEP1": "Step 1: Upload Files",
      "W1_STEP2": "Step 2: Select Source",
      "W1_STEP3": "Step 3: Approve Import",
      "W2_STEP1": "Step 1: Upload Files",
      "W2_STEP2": "Step 2: Feature Type",
      "W2_STEP3": "Step 3: Select or Create Field",
      "W2_STEP4": "Step 4: Approve Import",
      "W1_CHOICE_GRAPPLE": "Grapple",
      "W1_CHOICE_DELIMBER": "Delimber",
      "W1_CHOICE_HARVESTER": "Harvester",
      "W1_CHOICE_HUMAN": "Human",
      "W1_UNKNOWN_SECTOR": "Unknown",
      "W2_CHOICE_CONTOUR": "Contour",
      "W2_CHOICE_ISLAND": "Exclusions",
       "W2_CHOICE_ROAD": "Road",
       "W2_STEP_3_TITLE": "Confirm Field Names",
       "W2_STEP_3_USE_SAME_CHECKBOX": "Use same field name for all",
       "FIELD_NAME": "Field Name",
      "Are you sure you want to leave?": "Are you sure you want to navigate?",
      "PENDING": "Pending",
        "FAILED": "Failed",
        "COMPLETED": "Complete",
        "PROCESSING": "Processing",
        "APPROVED": "Approved",
        "WAITING": "Waiting",
        "UPLOADED": "Uploaded",
        "IGNORED": "Ignored",
        "Processing in the Last 24 Hours": "Processing in the Last 24 Hours",
        "Completed or Failed in the Last 24 Hours" : "Completed or Failed in the Last 24 Hours",
        "Older than 24 Hours": "Older than 24 Hours",
    }
  },
  fr: {
    translation: {
        "GPX_IMPORTER_WIZARD_TITLE": "Importateur GPX",
        "GPX_IMPORTER_WIZARD_DESCRIPTION": "Utilisez cet outil pour importer des fichiers GPX bruts avec des pistes GPS liées uniquement à Harvester, Grapple, Delimber ou Humain (balisage).",
        "PLAN_IMPORTER_WIZARD_TITLE": "Importateur de Planification",
        "PLAN_IMPORTER_WIZARD_DESCRIPTION": "Téléchargez des contours, des îlots d'exclusion ou des formes de planification de routes sous forme de fichiers zip séparés. Chaque type de forme (contour, île, route) doit être dans son propre fichier zip, nommé en conséquence (par exemple, \"contour_1.zip\" pour un contour). Assurez-vous que chaque fichier zip contient tous les composants de fichier de forme nécessaires (.shp, .shx, .rpj, etc.). Vous pouvez télécharger plusieurs fichiers zip simultanément, mais ils ne doivent pas être combinés en un seul fichier zip.",
        "BUTTON_UPLOAD": "Télécharger",
        "BUTTON_IMPORT": "Importer",
        "BUTTON_NEXT": "Suivant",
        "W1_STEP1": "Étape 1 : Télécharger des fichiers",
        "W1_STEP2": "Étape 2 : Sélectionner la source",
        "W1_STEP3": "Étape 3 : Approuver l'importation",
        "W2_STEP1": "Étape 1 : Télécharger des fichiers",
        "W2_STEP2": "Étape 2 : Type de fonction",
        "W2_STEP3": "Étape 3 : Sélectionner ou créer un chantier",
        "W2_STEP4": "Étape 4 : Approuver l'importation",
        "W1_CHOICE_GRAPPLE": "Grappin",
        "W1_CHOICE_DELIMBER": "Ébrancheur",
        "W1_CHOICE_HARVESTER": "Abatteuse",
        "W1_CHOICE_HUMAN": "Humain",
        "W1_UNKNOWN_SECTOR": "Inconnu",
        "W2_CHOICE_CONTOUR": "Contour",
        "W2_CHOICE_ISLAND": "Exclusions",
        "W2_CHOICE_ROAD": "Route",
        "W2_STEP_3_TITLE": "Confirmer les noms de chantier",
        "W2_STEP_3_USE_SAME_CHECKBOX": "Utiliser le même nom de chantier pour tous",
        "FIELD_NAME": "Nom du chantier",
        "Are you sure you want to leave?": "Êtes-vous sûr de vouloir naviguer ?",
        "PENDING": "En attente",
        "FAILED": "Échoué",
        "COMPLETED": "Terminé",
        "PROCESSING": "En cours de traitement",
        "APPROVED": "Approuvé",
        "WAITING": "En attente",
        "UPLOADED": "Téléchargé",
        "IGNORED": "Ignoré",
        "Processing in the Last 24 Hours": "Traitement dans les dernières 24 heures",
        "Completed or Failed in the Last 24 Hours": "Terminés ou échoués dans les dernières 24 heures",
        "Older than 24 Hours": "Plus ancien que 24 heures",
      }
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "fr", // default language, you can change it to "fr" for French, etc.
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
