import React from 'react';
import { useTranslation } from 'react-i18next';
import SlSpinner from '@shoelace-style/shoelace/dist/react/spinner';


const useBreadcrumbs = (stepNames, currentStep, stepIsValid) => {
  const { t } = useTranslation();
  
  const renderBreadcrumbs = (spinner = false) => (
    <div className="breadcrumbs">
      {stepNames.map((name, index) => (
        <div
          key={index}
          style={{ fontWeight: currentStep === index + 1 ? 'bold' : 'normal' }}
          className={`breadcrumb ${currentStep === index + 1 ? 'active' : ''} ${stepIsValid(index + 1) ? 'valid' : ''}`}
        >
          {t(name)}
        </div>
      ))}
      {spinner && <SlSpinner />}
    </div>
  );

  return renderBreadcrumbs;
};

export default useBreadcrumbs;