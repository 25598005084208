import { useState } from 'react';
import { useAuth } from 'react-oidc-context';
const useFileUpload = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [fileStatuses, setFileStatuses] = useState({});
  const [fileChecksums, setFileChecksums] = useState({});//[fileName]: checksum
  const [uploadBusy, setUploadBusy] = useState(false);
  const [geoJsonData, setGeoJsonData] = useState({});
  const auth = useAuth();
  const handleFileChange = (e) => {
    const filesArray = Array.from(e.target.files);
    setSelectedFiles(filesArray);
    setFileStatuses({});
    setGeoJsonData({});
  };
  const nonFailedFiles = selectedFiles.length > 0 ?
  selectedFiles.filter(file => fileStatuses[file.name] !== 'failed') : 
  []; 

  const handleUpload = async (apiEndpoint) => {
    setUploadBusy(true);
    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append('files', file);
    });

    try {
      const uploadResponse = await fetch(apiEndpoint, {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + auth.user.access_token,
        },
        body: formData,
      });
      const uploadData = await uploadResponse.json();

      let newFileStatuses = {};
      let newFileChecksums = {};
      uploadData.forEach((item) => {
        let fileName = item.originalname;
        console.log('fileName', fileName, item.status)   
        newFileStatuses[fileName] = item.status;
        newFileChecksums[fileName] = item.checksum;
      });
      setFileStatuses(newFileStatuses);
      setFileChecksums(newFileChecksums);
      const successfulUploads = uploadData.filter((item) => item.status !== 'failed').map((item) => item.originalname);
        
        
      console.log({successfulUploads})
      const geoJsonResponses = await Promise.all(
        
        successfulUploads.map((fileName) => {
          const checksum = newFileChecksums[fileName];
          const endpoint = apiEndpoint.replace('upload', 'preview') + '/' + checksum;
          console.log('endpoint', endpoint, fileName, checksum)
          return fetch(endpoint, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + auth.user.access_token,
            },
          }).then((response) => {
            return response.json()
          });
        })
      );

      const newGeoJsonData = {};
      successfulUploads.forEach((fileName, index) => {
        newGeoJsonData[fileName] = geoJsonResponses[index];
      });
      setGeoJsonData(newGeoJsonData);
    } catch (error) {
      console.error('Error:', error);
    }
    setUploadBusy(false);
  };
  const uploadComplete = () => {
    // console.log('uploadComplete', nonFailedFiles, fileStatuses)

    return nonFailedFiles.length > 0 && nonFailedFiles.every(file => fileStatuses[file.name] !== undefined);
};
  return {
    selectedFiles,
    fileStatuses,
    uploadBusy,
    geoJsonData,
    nonFailedFiles,
    fileChecksums,
    uploadComplete,
    handleFileChange,
    handleUpload,
  };
};

export default useFileUpload;
