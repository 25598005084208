import React, { useEffect } from 'react';
import 'ol/ol.css';
import { Map, View } from 'ol';
import TileLayer from 'ol/layer/Tile';
import { Vector as VectorSource } from 'ol/source';
import VectorLayer from 'ol/layer/Vector';
import { GeoJSON } from 'ol/format';
import {  buffer } from 'ol/extent';
import { Style, Stroke, Fill } from 'ol/style';
import {TileWMS} from 'ol/source';
const OpenLayersMap = ({
  geoJson,
  static: isStatic = false,
  noBackground = false,
  pointFillColor = '#5ce9b133',
  pointStrokeColor = '#5ce9b1',
  lineFillColor = '#5ce9b1ff',
  lineStrokeColor = '#5ce9b1ff',
  polygonFillColor = '#5ce9b133',
  polygonStrokeColor = '#5ce9b1ff',
}) => {
  const randomUniqueId = () => Math.random().toString(36).substr(2, 9);
  const mapID = 'map-' +  randomUniqueId();
  useEffect(() => {

    const format = new GeoJSON();
    const features = format.readFeatures(geoJson, {
      featureProjection: 'EPSG:3857',
    });
    console.log({features})
    const styleFunction = (feature) => {
      const geometryType = feature.getGeometry().getType();
      console.log({geometryType})
      let fill = null;
      let stroke = null;

      if (geometryType === 'Point' || geometryType === 'MultiPoint') {
        fill = new Fill({ color: pointFillColor });
        stroke = new Stroke({ color: pointStrokeColor, width: 2 });
      } else if (geometryType === 'LineString' || geometryType === 'MultiLineString') {
        fill = new Fill({ color: lineFillColor });
        stroke = new Stroke({ color: lineStrokeColor, width: 3 });
      } else if (geometryType === 'Polygon' || geometryType === 'MultiPolygon') {
        fill = new Fill({ color: polygonFillColor });
        stroke = new Stroke({ color: polygonStrokeColor , width: 2});
      }

      return new Style({
        fill,
        stroke,
      });
    };

    const vectorSource = new VectorSource({
      features,
    });

    const vectorLayer = new VectorLayer({
      source: vectorSource,
      style: styleFunction,
    });

    // Create the WMS source
const wmsSource = new TileWMS({
    url: 'https://geoegl.msp.gouv.qc.ca/ws/mffpecofor.fcgi',
    params: {
      'VERSION': '1.3.0',
      'LAYERS': 'sentinel_2023_prelim',
      'FORMAT': 'image/png',
      'CRS': 'EPSG:6622',
      'dpiMode': 7,
      'styles': '',
      'tilePixelRatio': 0
    },
    serverType: 'geoserver',  // Assuming it's a GeoServer; adjust as needed
  });
  

    const baseLayer = new TileLayer({
      source: wmsSource
    });

    baseLayer.on('prerender', (evt) => {
        if (evt.context) {
          const context = evt.context ;
          context.filter = 'grayscale(100%) brightness(80%) contrast(50%)';
          context.globalCompositeOperation = 'source-over';
        }
    });
      
    baseLayer.on('postrender', (evt) => {
        if (evt.context) {
         const context = evt.context;
         context.filter = 'none';
       }
    });

    const map = new Map({
      target: mapID,
      layers: noBackground ? [vectorLayer] : [baseLayer, vectorLayer], // Conditional layering
      view: new View({
        center: [0, 0],
        zoom: 2,
      }),
      interactions: isStatic ? [] : undefined,
      controls: isStatic ? [] : undefined,
    });
    console.log(map)
    const extent = vectorSource.getExtent();
    const bufferedExtent = buffer(extent, 5000);
    map.getView().fit(bufferedExtent, { padding: [10, 10, 10, 10] });
   
    return () => {
        map.setTarget(null);
    };
  }, [mapID, geoJson, isStatic, noBackground, pointFillColor, pointStrokeColor, lineFillColor, lineStrokeColor, polygonFillColor, polygonStrokeColor]);

  return <div id={mapID} className="openlayers-map" style={{ width: '100%', height: '360px' }} />;
};

export default OpenLayersMap;
