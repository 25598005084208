
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {useAuth} from 'react-oidc-context';
import SlButton from '@shoelace-style/shoelace/dist/react/button';
import SlCard from '@shoelace-style/shoelace/dist/react/card';
import SlSpinner from '@shoelace-style/shoelace/dist/react/spinner';
import SlRadioButton from '@shoelace-style/shoelace/dist/react/radio-button';
import SlRadioGroup from '@shoelace-style/shoelace/dist/react/radio-group';
import SlCheckbox from '@shoelace-style/shoelace/dist/react/checkbox';
import SlIcon from '@shoelace-style/shoelace/dist/react/icon';
// import SlInput from '@shoelace-style/shoelace/dist/react/input';
import OpenLayersMap from './OpenLayersMap';  // Adjust the path as needed
import * as turf from '@turf/turf';
import useFetchWithAuth from '../hooks/useFetchWithAuth';
import useFileUpload from '../hooks/useFileUpload';
import useBreadcrumbs from '../hooks/useBreadcrumbs';
import useStepValidation from '../hooks/useStepValidation';
import usePrompt from '../hooks/usePrompt';

const Wizard2 = () => {
    const [isDirty, setIsDirty] = useState(false);
    const auth = useAuth();
    const fetch = useFetchWithAuth(auth);

    const { t } = useTranslation();
    const { fileChecksums, setFileChecksums, nonFailedFiles, geoJsonData, selectedFiles, fileStatuses, uploadBusy, uploadComplete,handleFileChange, handleUpload } = useFileUpload();
    const stepNames = [
        t('W2_STEP1'),
       t('W2_STEP2'),
         t('W2_STEP3'),
        t('W2_STEP4'),
        ];

    // State variables and initial setup similar to Wizard1
    const [currentStep, setCurrentStep] = useState(1);

    const [shapeTypes, setShapeTypes] = useState({});  // To hold the type of each shapefile
    //  state variable to track if the upload has been triggered
  
    //  state variable to track if the upload is busy
    const [wizardBusy, setWizardBusy] = useState(false);
    const [fieldNames, setFieldNames] = useState({});
    const [commonFieldName, setCommonFieldName] = useState("");
    const [useCommonField, setUseCommonField] = useState(false);

  // state to keep track of files selected for import
  const [selectedForImport, setSelectedForImport] = useState({});
  const stepIsValid = (step) => {
        // console.log('stepIsValid', step)

        switch (step) {
        case 1:
            return nonFailedFiles.length > 0 && uploadComplete();
        case 2:
            // console.log({shapeTypes},  stepIsValid(1) && Object.keys(shapeTypes).length === nonFailedFiles.length && Object.values(shapeTypes).every((type) => type !== ''))
            return stepIsValid(1) && Object.keys(shapeTypes).length === nonFailedFiles.length && Object.values(shapeTypes).every((type) => type !== '');
        case 3:
            console.log( {nonFailedFiles,fieldNames}, stepIsValid(2) && Object.keys(fieldNames).length === nonFailedFiles.length && Object.values(fieldNames).every((name) => name !== ''))
            return stepIsValid(2) && Object.keys(fieldNames).length === nonFailedFiles.length && Object.values(fieldNames).every((name) => name !== '');
        case 4:
            return stepIsValid(3) && Object.keys(selectedForImport).length > 0;
        default:
            return false;
        }
  };
  const renderBreadcrumbs = useBreadcrumbs(stepNames, currentStep, stepIsValid);
  const isValid = useStepValidation(currentStep, stepIsValid);

  const navigate = useNavigate();
 const handleFieldNameChange = (fileName, fieldName) => {
    // Transform the field name to uppercase and remove special characters
    const sanitizedFieldName = fieldName.toUpperCase().replace(/[^A-Z0-9_ ]+/g, '');
    setFieldNames({
      ...fieldNames,
      [fileName]: sanitizedFieldName,
    });
  };
  
  const handleCommonFieldNameChange = (e) => {
    // Transform the field name to uppercase and remove special characters
    const sanitizedFieldName = e.target.value.toUpperCase().replace(/[^A-Z0-9_ ]+/g, '');
    setCommonFieldName(sanitizedFieldName);
  
    if (useCommonField) {
      const newFieldNames = {};
      selectedFiles.forEach((file) => {
        newFieldNames[file.name] = sanitizedFieldName;
      });
      setFieldNames(newFieldNames);
    }
  };
 


  const niceFileSize = (bytes) => {
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return (
        (bytes / Math.pow(1024, i)).toFixed(2) * 1 +
        ' ' +
        ['B', 'kB', 'MB', 'GB', 'TB'][i]
    );
  };

  const handleShapeTypeChange = (fileName, selectedType) => {
    console.log('handleShapeTypeChange', fileName, selectedType)
    setShapeTypes(prevState => ({ ...prevState, [fileName]: selectedType }));

  };


  const toggleFileForImport = (fileName) => {
    setSelectedForImport(prevState => ({
      ...prevState,
      [fileName]: !prevState[fileName]
    }));
  };
  
 


  // Define the styles similar to Shoelace's SlInput
const customInputStyle = {
    padding: '12px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    width: '100%',
    boxSizing: 'border-box',
    fontSize: '16px',
  };
  
  usePrompt(t('Are you sure you want to leave?'),  isDirty);

  const [existingFieldNames, setExistingFieldNames] = useState([]);
  useEffect(()=>{
    if(currentStep === 2){
        let nonFailedFilesNames = nonFailedFiles.map(file => file.name);
        let importDefaults = {};
        nonFailedFilesNames.forEach(name => {
            importDefaults[name] = true;
        });
        setSelectedForImport(importDefaults); 
        setIsDirty(true);

    }
  }, [currentStep])
  useEffect(() => {
    console.log({selectedForImport})
    if(!uploadComplete()) return;
    if(existingFieldNames.length > 0) return;
    if(!geoJsonData) return;
   
    if(currentStep !== 3) return;
    setWizardBusy(true);
    fetch('/api/sector_names', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + auth.user.access_token,
        }
      })
      .then(response => response.json())
      .then(data => {
        // Assume data is now an array of objects [{name, polygon}, ...]
        const names = data.map(field => field.name);
        setExistingFieldNames(names);
        const updatedFieldNames = {};

        // For each non-failed file
        nonFailedFiles.forEach((file) => {
          const shapes = geoJsonData[file.name].features;
            console.log({shapes})
          // Check each shape to see if it falls within any of the existing polygons
          shapes.forEach((shape) => {
              data.forEach(({ name, geojson }) => {
                // if (geojson.type === 'MultiPolygon') {
                //   geojson.coordinates.forEach((polygon) => {
                //     const poly = { type: 'Polygon', coordinates: polygon };
                //     if (turf.booleanIntersects(shape, poly)) {
                //       updatedFieldNames[file.name] = name;
                //     }
                //   });
                // } else if (geojson.type === 'Polygon') {
                //   // Handle Polygon as before
                  if (turf.booleanIntersects(shape, geojson)) {
                    updatedFieldNames[file.name] = name;
                  }
                // }
              });
          });
        });
  
        // Update fieldNames state
        setFieldNames(updatedFieldNames);
        setWizardBusy(false);
      })
  }, [currentStep,geoJsonData])
  
  

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        // Upload logic here
         return (
            <div>
                <div className='importer-upload'>
                    <input  type="file" accept=".zip" multiple onChange={handleFileChange} disabled={uploadBusy} />
                    <SlButton variant="neutral" onClick={()=> handleUpload('/api/files/upload')} disabled={uploadBusy}>
                        <SlIcon slot="suffix" name="upload"></SlIcon>
                        {t('BUTTON_UPLOAD')}
                    </SlButton>
                </div>
            {/* Display file statuses */}
            <ul className='uploaded-file-states'>
              {selectedFiles.map((file) => (
                <li key={file.name} className={fileStatuses[file.name] || 'pending' }>
                    <span className='upload-file-name'>{file.name}</span>
                    <span className='upload-file-size'>{niceFileSize(file.size)} bytes</span>
                    {fileStatuses[file.name] && <span className='upload-file-status'>{t(fileStatuses[file.name])}</span>}
                    {uploadBusy &&!fileStatuses[file.name] && <SlSpinner style={{ marginLeft: 1 + 'rem' }} />}
                </li>
              ))}
            </ul>
          </div>
        );
      case 2:
        return (
            <div className='shapefile-source-selector table'>
            {/* Display choices for non-failed files */}
            {nonFailedFiles.map((file) =>{
                console.log({
                    map: file,
                    data:geoJsonData[file.name]
                })
                const isLine = geoJsonData[file.name]?.features.some(
                    (feature) => feature.geometry.type === 'LineString'
                  );
                  if(isLine && shapeTypes[file.name] !== 'chemin') {
                        setShapeTypes(prevState => ({ ...prevState, [file.name]: 'chemin' }));
                  }
                return (
                    <div key={file.name} className='source-selector table-row'>
                    <div className='source-selector-preview-map table cell'>
                        {geoJsonData[file.name] && (
                        <OpenLayersMap geoJson={geoJsonData[file.name]}  />
                        )}
                    </div>
                    <div className="flex-spacer"></div>
                    <div className="table-cell source-selector-fields">
                        <h3 className="source-selector-label">{file.name}</h3>
                        <SlRadioGroup size="medium" onSlChange={(e) => handleShapeTypeChange(file.name, e.target.value)} value={shapeTypes[file.name] || ""}>
                        <SlRadioButton value="contour" disabled={isLine}>{t('W2_CHOICE_CONTOUR')}</SlRadioButton >
                        <SlRadioButton value="ilot" disabled={isLine}>{t('W2_CHOICE_ISLAND')}</SlRadioButton>
                        <SlRadioButton value="chemin" disabled={!isLine}>{t('W2_CHOICE_ROAD')}</SlRadioButton>
                        </SlRadioGroup>
                    </div>
                    </div>
                )
            } )}
            </div>
        );
        case 3:
            return (
                <div>
                  <h3>{t('W2_STEP_3_TITLE')}</h3>
                  <SlCheckbox 
                    checked={useCommonField} 
                    onSlChange={() => setUseCommonField(!useCommonField)}
                    disabled={wizardBusy}
                  >
                  {t('W2_STEP_3_USE_SAME_CHECKBOX')}
                  </SlCheckbox>
                  <datalist id="existingFieldNames">
                    {existingFieldNames.map((name, index) => <option key={index} value={name} />)}
                  </datalist>
                  {useCommonField && (
                    <div className="common-field-name">
                        <label htmlFor="commonFieldName">{t('FIELD_NAME')}</label>
                        <input 
                            id="commonFieldName"
                            list="existingFieldNames" 
                        type="text" 
                        placeholder={t('FIELD_NAME')} 
                        style={customInputStyle}
                        value={commonFieldName}
                        disabled={wizardBusy}
                        onChange={handleCommonFieldNameChange}
                        />
                    </div>
                  )}
                  
                  <div className={"planning-importer-naming table " + (useCommonField ? 'common' : '') }>
                    {nonFailedFiles.map((file) => (
                      <div key={file.name} className='table-row'>
                        <div className='planning-importer-naming-map table-cell'>
                            <OpenLayersMap className="" geoJson={geoJsonData[file.name]} static noBackground />
                        </div>
                        <div className='naming-item table-cell'>
                        <h3>{file.name}</h3>
                        <input 
                          type="text" 
                          className='naming-item-input'
                          list="existingFieldNames" 
                          placeholder={t('Field Name')}
                          style={customInputStyle}
                          value={useCommonField ? commonFieldName : (fieldNames[file.name] || '')}
                          disabled={wizardBusy}
                          onChange={(e) => !useCommonField && handleFieldNameChange(file.name, e.target.value)}
                          readOnly={useCommonField}
                        />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              );
        case 4:
            return (
                <div className='planning-import-confirmation table'>
                  {nonFailedFiles.map((file) => (
                      <div key={file.name}  className="table-row">
                           <div className='import-confirmation-preview-map table-cell'>
                        {geoJsonData[file.name] && (
                         <OpenLayersMap  static noBackground geoJson={geoJsonData[file.name]} />
                         )}
                      </div>
                      <div className="flex-spacer"></div>
                    <div className='import-item table-cell'>
                      <h3 className='import-item-label'>{file.name}</h3>
                          <label className='import-checkbox'>
                          𝦴 <strong className="import-type">{shapeTypes[file.name]}</strong>  ⌘&nbsp;<strong className="import-name">{fieldNames[file.name]}</strong> 
                          <span className="flex-spacer"></span>
                          <SlCheckbox
                      size="large"
                        checked={selectedForImport[file.name] }
                        onSlChange={() => toggleFileForImport(file.name)}
                      >
   
                      </SlCheckbox>
                          </label>
                      
                    </div>
                    </div>
                  ))}
  
                </div>
              );
            
      // More steps will be added as we go along
      default:
        return null;
    }
  };

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  }

  const handleSubmit = () => {
    console.log('HERE',{selectedForImport, nonFailedFiles, shapeTypes, fieldNames})
    const data = {
        files: Object.keys(selectedForImport),
        options: {
            shapeTypes,
            fieldNames,
        },
        wizard: 'planning',
        checksums: fileChecksums
    }
    console.log(`Submitting data: ${JSON.stringify(data)}...`);
    fetch('/api/tasks/import', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + auth.user.access_token,
        },
        body: JSON.stringify(data)
        }).then( response => {
            console.log({response})
            // navigate to /tasks
            window.requestAnimationFrame(() => navigate('/tasks'));
        }).catch( (error) => {
            console.error('Error:', error);
        }).finally(() => {
            setIsDirty(false);
        });
    // Mock the worker process and task creation
    console.log('Send to tasker:', data);
  }

  return (
    <div className='wizard'>
        <h1 className="wizard-title">{t('PLAN_IMPORTER_WIZARD_TITLE')}</h1>
        <p className="wizard-notes">{t('PLAN_IMPORTER_WIZARD_DESCRIPTION')}</p>
        <SlCard>
            <div slot="header">
            {renderBreadcrumbs(wizardBusy)}
            </div>
            {/* Display step content */}
                {renderStepContent()}
            {/* Navigation buttons */}
        {/* {currentStep > 1 && <SlButton type="default" onClick={prevStep}>Previous</SlButton>} */}
        <div slot="footer" style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span className="spacer"></span>
            {currentStep < stepNames.length && (
            <SlButton 
                variant="primary"
                onClick={nextStep}
                disabled={!stepIsValid(currentStep)}
            >{t('BUTTON_NEXT')}</SlButton>
            )}
            {currentStep === stepNames.length && <SlButton variant="success" onClick={handleSubmit}>{t('BUTTON_IMPORT')}</SlButton>}
        </div>
        </SlCard>
  </div>
  );
};

export default Wizard2;
