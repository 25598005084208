/* we want to render the file from public/gestion-cirrus-logo-white.svg as a component */

import React from 'react';

const CirrusLogo = () => {

    return (
        <div className="cirrus-logo">
             <img className="cirrus-logo-image" src="/gestion-cirrus-logo-white.svg" alt="Gestion Cirrus Logo" />
        </div>
    );
    };

export default CirrusLogo;