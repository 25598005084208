import React, { useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { useAuth } from 'react-oidc-context';
import { useTranslation } from 'react-i18next';
import SlButton from '@shoelace-style/shoelace/dist/react/button';
import SlCard from '@shoelace-style/shoelace/dist/react/card';
import SlIcon from '@shoelace-style/shoelace/dist/react/icon';
import SlSpinner from '@shoelace-style/shoelace/dist/react/spinner';
import SlRadioButton from '@shoelace-style/shoelace/dist/react/radio-button';
import SlRadioGroup from '@shoelace-style/shoelace/dist/react/radio-group';
import SlCheckbox from '@shoelace-style/shoelace/dist/react/checkbox';
import OpenLayersMap from './OpenLayersMap';  // Adjust the path as needed
import useFetchWithAuth from '../hooks/useFetchWithAuth';
import useFileUpload from '../hooks/useFileUpload';
import useBreadcrumbs from '../hooks/useBreadcrumbs';
import useStepValidation from '../hooks/useStepValidation';
import * as turf from '@turf/turf';

import usePrompt from '../hooks/usePrompt';
// import { set } from 'ol/transform';
const Wizard1 = () => {
  const [isDirty, setIsDirty] = useState(false);
  const { t } = useTranslation();
  const { fileChecksums, setFileChecksums, nonFailedFiles, geoJsonData, selectedFiles, fileStatuses, uploadBusy, uploadComplete, handleFileChange, handleUpload } = useFileUpload();
  const auth = useAuth();
  const fetch = useFetchWithAuth(auth);
  const stepNames = [
    t('W1_STEP1'), 
    t('W1_STEP2'),
    t('W1_STEP3'),
  ];

  //  state variable to keep track of the current step
  const [currentStep, setCurrentStep] = useState(1);

  // state to keep track of files selected for import
  const [selectedForImport, setSelectedForImport] = useState({});

  // state to hold machiune types
  const [machineTypes, setMachineTypes] = useState({});

  const allOptionsSelected = nonFailedFiles.length > 0 && nonFailedFiles.every(file => machineTypes[file.name] !== undefined);

  const [knownFieldNames, setKnownFieldNames] = useState({});
  const stepIsValid = (step) => { 
    // console.log('stepIsValid', step,uploadComplete() )
    switch (step) {
        case 1:
            return nonFailedFiles.length > 0 && uploadComplete();
        case 2:
            // console.log({machineTypes})
            return nonFailedFiles.length > 0 && nonFailedFiles.every(file => machineTypes[file.name] !== undefined && machineTypes[file.name] !== '');
        case 3:
            return allOptionsSelected;
        default:
            return true;
    }
};  
  const renderBreadcrumbs = useBreadcrumbs(stepNames, currentStep, stepIsValid);
  const isValid = useStepValidation(currentStep, stepIsValid);

  const navigate = useNavigate();

  // Function to go to the next step
  const nextStep = () => {
    setCurrentStep(prevStep => prevStep + 1);
  };


  const handleMachineTypeChange = (fileName, selectedType) => {
    // console.log('handleMachineTypeChange', fileName, selectedType)
    setMachineTypes(prevState => ({ ...prevState, [fileName]: selectedType }));
  };

  usePrompt(t('Are you sure you want to leave?'),  isDirty);

  useEffect(()=>{
    if(currentStep === 2){
        let nonFailedFilesNames = nonFailedFiles.map(file => file.name);
        let importDefaults = {};
        nonFailedFilesNames.forEach(name => {
            importDefaults[name] = true;
        });
        setSelectedForImport(importDefaults); 
        setIsDirty(true);
        console.log({isDirty})
    }
    if(currentStep === 3){
        console.log('fetching field names')
        fetch('/api/sector_names', {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + auth.user.access_token,
            }
          })
          .then(response => response.json())
          .then(data => {
            const updatedFieldNames = {};
            console.log('FIELDNAMES', data)
            nonFailedFiles.forEach((file) => {
              const shapes = geoJsonData[file.name].features;
              console.log({shapes});
          
              data.forEach(({ name, geojson }) => {
                shapes.forEach((shape) => {
                  // Check if geometry is MultiLineString
                  if (shape.geometry.type === 'MultiLineString') {
                    shape.geometry.coordinates.forEach((lineString) => {
                      const lineGeom = {
                        type: 'LineString',
                        coordinates: lineString
                      };
                      // Check each LineString separately
                      if (turf.booleanWithin(lineGeom, geojson)) {
                        if(updatedFieldNames[file.name]){
                            updatedFieldNames[file.name].push(name);
                            updatedFieldNames[file.name] = [...new Set(updatedFieldNames[file.name])];
                        }else{
                            updatedFieldNames[file.name] = [name];
                        }
                      }
                    });
                  } else {
                    // For other geometries, use booleanWithin directly
                    if (turf.booleanWithin(shape, geojson)) {
                      updatedFieldNames[file.name] = name;
                    }
                  }
                });
              });
            });
            console.log({updatedFieldNames})
            setKnownFieldNames(updatedFieldNames);
          })
          .catch(error => {
            console.error('Error fetching field names:', error);
          });
          
    }
  }, [currentStep])

  const toggleFileForImport = (fileName) => {
    setSelectedForImport(prevState => ({
      ...prevState,
      [fileName]: !prevState[fileName]
    }));
  };

  // use effect to automatically select all files for import when the upload is complete 

  const handleSubmit = () => {
    // Submission logic here, you will have access to `selectedForImport` to know which files are selected
    const fieldNames = {};
    
    Object.keys(knownFieldNames).forEach((file) => {
        const fieldName = knownFieldNames[file];
        if(selectedForImport[file]){
            fieldNames[file] = Array.isArray(fieldName) ? fieldName.join(', ') : fieldName;
        }
    });
    const data = {
        files: Object.keys(selectedForImport),
        options: {machineTypes,fieldNames },
        wizard: 'tracks',
        checksums: fileChecksums
    }
    fetch('/api/tasks/import', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth.user.access_token}`,
        },
        body: JSON.stringify(data)
        }).then( response => {
            setIsDirty(false);
            setTimeout(() =>{ navigate('/tasks') }, 100);

        }).catch( error => {
            console.error('Error:', error);
        });
  };
                                                                                                                                                                                                                                                           

  const niceFileSize = (bytes) => {
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return (
        (bytes / Math.pow(1024, i)).toFixed(2) * 1 +
        ' ' +
        ['B', 'kB', 'MB', 'GB', 'TB'][i]
    );
  };


  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <div>
            <div className='importer-upload'>
            <input  type="file" accept=".gpx" multiple onChange={handleFileChange} disabled={uploadBusy} />
            <SlButton variant="neutral" onClick={()=> handleUpload('/api/files/upload')} disabled={uploadBusy}>
            <SlIcon slot="suffix" name="upload"></SlIcon>
                {t('BUTTON_UPLOAD')}
            </SlButton>
            </div>

            {/* Display file statuses */}
            <ul className='uploaded-file-states'>
              {selectedFiles.map((file) => (
                <li key={file.name} className={fileStatuses[file.name] || 'pending' }>
                    <span className='upload-file-name'>{file.name}</span>
                    <span className='upload-file-size'>{niceFileSize(file.size)} bytes</span>
                    {fileStatuses[file.name] && <span className='upload-file-status'>{t(fileStatuses[file.name]?.toUpperCase())}</span>}
                    {uploadBusy &&!fileStatuses[file.name] && <SlSpinner style={{ marginLeft: 1 + 'rem' }} />}
                </li>
              ))}
            </ul>
          </div>
        );
  
        case 2:
            return (
              <div className='gpx-source-selector table'>
                {/* Display choices for non-failed files */}
                {nonFailedFiles.map((file) => (
                  <div key={file.name} className='source-selector table-row'>
                    <div className='source-selector-preview-map table cell'>
                      {geoJsonData[file.name] && (
                       <OpenLayersMap geoJson={geoJsonData[file.name]} />
                       )}
                    </div>
                        <div className="flex-spacer"></div>
                    <div className="table-cell source-selector-fields">
                    <h3 className="source-selector-label">{file.name}</h3>
                    <SlRadioGroup size="large"  onSlChange={(e) => handleMachineTypeChange(file.name, e.target.value)}>
                        <SlRadioButton value="Grapel">{t('W1_CHOICE_GRAPPLE')}</SlRadioButton>
                        <SlRadioButton value="Ebrancheuse">{t('W1_CHOICE_DELIMBER')}</SlRadioButton>
                        <SlRadioButton value="Bucheuse">{t('W1_CHOICE_HARVESTER')}</SlRadioButton>
                        <SlRadioButton value="Humain">{t('W1_CHOICE_HUMAN')}</SlRadioButton>
                    </SlRadioGroup>
                    </div>
                  </div>
                ))}
              </div>
        );
        case 3:
            return (
              <div className='gpx-import-confirmation table'>
                {nonFailedFiles.map((file) => (
                    <div key={file.name}  className="table-row">
                         <div className='import-confirmation-preview-map table-cell'>
                         {geoJsonData[file.name] && (
                            <OpenLayersMap  static noBackground geoJson={geoJsonData[file.name]} />
                         )}
                    </div>
                    <div className="flex-spacer"></div>
                  <div className='import-item table-cell'>
                    <h3 className='import-item-label'>{file.name}</h3>
                        <label className='import-checkbox'>
                        𝦴 <strong className="machine-type">{ t(machineTypes[file.name])}</strong> ⌘&nbsp;{ knownFieldNames[file.name] ? <strong className='field-name'>{ knownFieldNames[file.name]}</strong> : t('W1_UNKNOWN_SECTOR')}
                        <span className="flex-spacer"></span>
                        <SlCheckbox
                      size="large"
                      checked={selectedForImport[file.name] || true}
                      onSlChange={() => toggleFileForImport(file.name)}
                    >
                       
                    </SlCheckbox>
                        </label>
                    
                  </div>
                  </div>
                ))}

              </div>
            );
  
      default:
        return null;
    }
  };

  return (
    <div className='wizard'>
      <h1 className="wizard-title">{t('GPX_IMPORTER_WIZARD_TITLE')}</h1>
      <p className="wizard-notes">{t('GPX_IMPORTER_WIZARD_DESCRIPTION')}</p>
        <SlCard>
        <div slot="header">
      {renderBreadcrumbs(false)}
      </div>
      {/* Display step content */}
      {renderStepContent()}
      
      {/* Navigation buttons */}
      {/* {currentStep > 1 && <SlButton type="default" onClick={prevStep}>Previous</SlButton>} */}
      <div slot="footer" style={{ display: 'flex', justifyContent: 'space-between' }}>
      <span className="spacer"></span>
      {currentStep <  stepNames.length && (
        <SlButton 
          variant="primary"
          onClick={nextStep}
          disabled={!isValid}
        >
          {t('BUTTON_NEXT')}
        </SlButton>
      )}
      {currentStep === stepNames.length && <SlButton variant="success" onClick={handleSubmit}>{t('BUTTON_IMPORT')}</SlButton>}
        </div>
      </SlCard>
    </div>
  );
  


};

export default Wizard1;
