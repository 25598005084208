import React, { useEffect, useState, useCallback } from 'react';
import SlCard from '@shoelace-style/shoelace/dist/react/card';
import SlRelativeTime from '@shoelace-style/shoelace/dist/react/relative-time';
import { useTranslation } from 'react-i18next';
import io from 'socket.io-client';
import SlProgressBar from '@shoelace-style/shoelace/dist/react/progress-bar';
import SlSpinner from '@shoelace-style/shoelace/dist/react/spinner';
import Task from '../utils/Task';
import { useAuth } from 'react-oidc-context';
import useFetchWithAuth from '../hooks/useFetchWithAuth';

// Initialize Socket.io
const socket = io();

// Mock function to fetch initial tasks
// Mock function to fetch initial tasks

const uniqueById = (array) =>
  array.filter((value, index, self) => 
    self.findIndex(item => item.id === value.id) === index
  );


const TasksPane = () => {
  const {t,i18n} = useTranslation();
  const i18nLang = i18n.language; 
  const [tasks, setTasks] = useState([]);
  const [error, setError] = useState(null);  // New state variable for errors
  const [tasksLoading, setTasksLoading] = useState(true);
  const auth = useAuth();
  const fetch = useFetchWithAuth(auth);
   // Function to fetch initial tasks
   
  const fetchInitialTasks = async (setError) => {
    try {
        
      const response = await fetch('/api/tasks', {
        headers: {
          Authorization: `Bearer ${auth.user.access_token}`,
        },
      });
      if (!response.ok) {  // Check if response is not okay
        const errorMsg = await response.text();
        setError(`Error ${response.status}: ${errorMsg}`);
        return [];
      }
      const data = await response.json();
      // validate data
      if (!Array.isArray(data)) {
        console.error("Invalid response from server:", data)
        setError('Invalid response from server');
        return [];
      }
      for( let item of data){
        console.log('item', item, typeof item)
        if (!Task.validate(item)){
          console.error("Invalid response from server:", data)
  
          setError('Invalid response from server');
          return [];
        }
      }
      console.log("result",  data)
      const result =  data.map((task) => new Task(task));
      return result
    } catch (error) {
      console.error("Could not fetch tasks:", error);
      setError("Could not fetch tasks. Please try again.");
      return [];
    }
}
  useEffect(() => {
    // const fetchInitialTasks = async (setError) => {
      
    // };
    // Fetch initial tasks
    const fetchData = async () => {
      setTasksLoading(true)
      let initialTasks = await fetchInitialTasks(setError);
      initialTasks = uniqueById(initialTasks);
      console.log('sets initialTasks', initialTasks)
      setTasks(initialTasks);
  
      // Store the active listeners for cleanup
      const activeListeners = [];
  
      // Listen to Socket.io messages for each uncompleted or unfailed task
      initialTasks.forEach(task => {
        if (task.status !== 'completed' && task.status !== 'failed') {
          const eventName = `task:${task.id}`;
          const listener = (updatedTask) => {
            setTasks(prevTasks =>{
              const updatedTasks =  prevTasks.map(t => t.id === updatedTask.id ? updatedTask : t);
              return uniqueById(updatedTasks);
            });
          };
          socket.on(eventName, listener);
          
          // Add to active listeners for later cleanup
          activeListeners.push({ eventName, listener });
        }
      });
      setTasksLoading(false)

      // Cleanup: remove listeners when component unmounts or dependencies change
      return () => {
        activeListeners.forEach(({ eventName, listener }) => {
          socket.off(eventName, listener);
        });
      };
    };
  
    fetchData();
    
  }, []);

 
  
  // Function to check if a task date is within the last 24 hours
  const isWithinLast24Hours = (date) => {
    const now = new Date();
    const oneDayAgo = new Date(now.getTime() - (24 * 60 * 60 * 1000));
    return new Date(date) >= oneDayAgo;
  };

  function taskSorter(a,b){
    // sort by time_start then by time_end
    if (a.time_start === null && b.time_start === null) {
      return 0;
    }
    if (a.time_start === null) {
      return -1;
    }
    if (b.time_start === null) {
      return 1;
    }
    if (a.time_start < b.time_start) {
      return 1;
    }
    if (a.time_start > b.time_start) {
      return -1;
    }
    if (a.time_end === null && b.time_end === null) {
      return 0;
    }
    if (a.time_end === null) {
      return -1;
    }
    if (b.time_end === null) {
      return 1;
    }
    if (a.time_end < b.time_end) {
      return 1;
    }
    if (a.time_end > b.time_end) {
      return -1;
    }
    return 0;
  }
  // Filter tasks
  
  const tasksCompletedOrFailedLast24H = tasks.filter((task) =>{ 
    return  (task.status === 'completed' || task.status === 'failed') && ((task.time_end && isWithinLast24Hours(task.time_end) )|| (!task.time_end && isWithinLast24Hours(task.time_start) ))
  }
  ).sort(taskSorter);
  
  const tasksProcessingLast24H = tasks.filter((task) =>{ 
    return (task.status === 'processing' || task.status === 'waiting' ) && isWithinLast24Hours(task.time_start)
  }).sort(taskSorter);

  const tasksOlderThan24H = tasks.filter(task => 
    !isWithinLast24Hours(task.time_end || task.time_start)
  ).sort(taskSorter);

  

   // Function to render a table
   const renderTable = (tasksToRender, title) =>{
    const keyPrefix = title.replace(/\s/g, '-').toLowerCase();
    return (
      <table className="tasks-table">
        <colgroup>
         <col style={{width:'8rem'}} />
         <col style={{width:'10rem'}} />
         <col style={{width:'5rem'}} />
         <col style={{width:'5rem'}} />
         <col style={{width:'7rem'}} />
         <col style={{width:'15rem'}} />
         <col style={{width:'3rem'}} />


        </colgroup>
        <thead>
          <tr>
            <th colSpan="7" className="table-title">{title}</th>
          </tr>
        </thead>
        <tbody>
        <tr>
              <th className="id">{t('ID')}</th>
              <th className="filename">{t('FILENAME')}</th>
              <th className="time_start">{t('START')}</th>
              <th className="time_end">{t('END')}</th>
              <th className="user">{t('USER')}</th>
              <th className="details">{t('DETAILS')}</th>
              <th className="status">{t('STATUS')}</th>
            </tr>
            {tasksToRender.length === 0 ? (<tr><td colSpan="7" style={{textAlign:'center'}}>...</td></tr>) : tasksToRender.map((task) => (
            <tr key={`${keyPrefix}-${task.id}`}>
              <td className="id" title={task.id}><code className='taskid'>{task.id}</code></td>
              <td className="filename" title={task.filename}>{task.filename}</td>
              <td className="time_start">
               { task.time_start === null ? <SlProgressBar indeterminate style={{ '--height': '6px' }}/> : <SlRelativeTime lang={i18nLang} format="narrow" date={task.time_start }  />}
              </td>
              <td className="time_end">
                {task.time_end === null ? task.status === 'failed' ?  <p>?</p> : <SlProgressBar indeterminate style={{ '--height': '6px' }}/> : <SlRelativeTime lang={i18nLang} format="narrow" date={task.time_end }  />}
              </td>
              <td className="user">{task.user}</td>
              <td className="details">
                <p>{task.details}</p>
                {task.status === 'failed' && <p className="failure-note">{task.error}</p>}
                </td>
              
              <td className={"status " + task.status }>
              {task.status === 'processing' ? <SlSpinner/> : t(task.status.toUpperCase())} 
              </td>
          
            </tr>
          ))}      
          </tbody>
      </table>
    );
   }

  return (
    <div className="tasks">
          {error && <div className="error-panel">{error}</div>}  {/* New error panel */}

      <h1 className="wizard-title">{t('Import Tasks')}</h1>
      { tasksLoading ? <SlSpinner/> : tasks.length === 0 ? <p>{t('No tasks')}</p> : 
      <SlCard>
  
          {renderTable(tasksProcessingLast24H, t('Processing in the Last 24 Hours'))}
          {renderTable(tasksCompletedOrFailedLast24H, t('Completed or Failed in the Last 24 Hours'))}
          {renderTable(tasksOlderThan24H, t('Older than 24 Hours'))}

      </SlCard>
      }
      <ul>
        
      </ul>
    </div>
  );
};

export default TasksPane;
