class Task {
    constructor({
      id = Date.now().toString(),
      time_start = new Date().toISOString(),
      time_end = null,
      status = 'waiting', // could be an enum if needed
      filename,
      checksum, 
      user = 'default_user',
      details = '', // could contain markdown if needed
      wizard,
      options = {}, // options can hold any task-specific settings
      progress = "unknown",
      error_info = null,
      human_intervention_required = false
    }) {
      this.id = id;
      this.time_start = time_start;
      this.time_end = time_end;
      this.status = status;
      this.filename = filename;
      this.checksum = checksum;
      this.user = user;
      this.details = details;
      this.wizard = wizard;
      this.options = options; // may be used to store options passed from the wizard
      this.progress = progress;
      this.error_info = error_info; // could be a string or an object with more structure
      this.human_intervention_required = human_intervention_required;
    }
    
    toJSON() {
      return {
        id: this.id,
        time_start: this.time_start,
        time_end: this.time_end,
        status: this.status,
        filename: this.filename,
        checksum: this.checksum,
        user: this.user,
        details: this.details,
        wizard: this.wizard,
        options: this.options||{},
        progress: this.progress||'unkown',
        error_info: this.error_info,
        human_intervention_required: this.human_intervention_required
      };
    }
}

Task.validate = (task) => {
    console.log(`Task.validate`, {task})
    // validates that the provided task can be made into a Task object
    // returns true if valid, false otherwise
    task.errors = {};
    
    // check that the id is a string
    if (typeof task.id !== 'string') {
        task.errors.id = 'id must be a string';
        return false;
    }

    // check that the time_start is a string, and can be parsed as a date
    if (typeof task.time_start !== 'string' || new Date(task.time_start) === 'Invalid Date') {
        task.errors.time_start = 'time_start must be a string that can be parsed as a date';
        return false;
    }

    // check that the time_end is either null or a string that can be parsed as a date
    if (task.time_end !== null && (typeof task.time_end !== 'string' || new Date(task.time_end) === 'Invalid Date')) {
        task.errors.time_end = 'time_end must be null or a string that can be parsed as a date';    
        return false;
    }

    // check that the status is a string
    if (typeof task.status !== 'string') {
        task.errors.status = 'status must be a string';
        return false;
    }

    // check that the filename is a string
    if (typeof task.filename !== 'string') {
        task.errors.filename = 'filename must be a string';
        return false;
    }

    // check that the checksum is a string
    if (typeof task.checksum !== 'string') {
        task.errors.checksum = 'checksum must be a string';
        return false;
    }

    // check that the user is a string
    if (typeof task.user !== 'string') {
        task.errors.user = 'user must be a string';
        return false;
    }

    // check that the details is a string
    if (typeof task.details !== 'string') {
        task.errors.details = 'details must be a string';
        return false;
    }

    // check that the wizard is a string
    if (typeof task.wizard !== 'string') {
        task.errors.wizard = 'wizard must be a string';
        return false;
    }

    // check that the options is an object
    if (task.options && typeof task.options !== 'object') {
        task.errors.options = 'options must be an object';
        return false;
    }

    // check that the progress is a string
    if (task.progress  && typeof task.progress !== 'string') {
        task.errors.progress = 'progress must be a string';
        return false;
    }

    // check that the error_info is either null or a string
    if (task.error_info !== null && task.error_info !== undefined && typeof task.error_info !== 'string') {
        return false;
    }

    // check that the human_intervention_required is a boolean
    if( task.human_intervention_required !== false && task.human_intervention_required !== true && task.human_intervention_required !== null && task.human_intervention_required !== undefined) {
        task.errors.human_intervention_required = 'human_intervention_required must be a boolean';
        return false;
    }

    return true;
}

// Node.js style export or ES6 style export
// This creates a factory function that is compatible with both module systems
export default Task;
